import { LaunchDarklyExperiment } from './launch-darkly-experiment';

export enum AnalyticsEvent {
  ActionableTabClicked = 'Actionable Tab Clicked',
  ActionFilterClicked = 'Action Filter Clicked',
  ActionFilterApplied = 'Action Filter Applied',
  ActionsMarked = 'Actions Marked',
  ActionsRemoved = 'Actions Removed',
  AddAllergyClicked = 'Add Allergies & Intolerances Clicked',
  AddCommentClicked = 'Add Comment Clicked',
  AddHealthGoalClicked = 'Add Health Goal Clicked',
  AddHistoryButtonClicked = 'Add History Button Clicked',
  AddHistoricalVaccineClicked = 'Add Historical Vaccine Clicked',
  AddMedicationClicked = 'Add Medication Clicked',
  AddScreeningHistoryClicked = 'Add Screening History Clicked',
  AddStepClicked = 'Add Step Clicked',
  AddVaccineClicked = 'Add Vaccine Clicked',
  AdmissionAttachmentSearchedInSelector = 'Admission Attachment Searched In Selector',
  AdmissionAttachmentDocumentSelected = 'Admisison Attachment document selected',
  AdmissionAttachmentSearchCancelled = 'Admisison Attachment search cancelled',
  AdmissionAttachmentLinked = 'Admission Attachment Linked',
  AdmissionAttachmentUnlinked = 'Admission Attachment Unlinked',
  AllScreeningsTabClicked = 'All Screenings Tab Clicked',
  AmazonPharmacyDetailsTableCollapsed = 'Amazon Pharmacy Details Table Collapsed',
  AmazonPharmacyDetailsTableExpanded = 'Amazon Pharmacy Details Table Expanded',
  AssignRepliesClicked = 'Assign Replies Clicked',
  ClinicalTimelineObjectClosed = 'Clinical Timeline Object Closed',
  ClinicalTimelineObjectOpened = 'Clinical Timeline Object Opened',
  CompleteTaskClicked = 'Complete Task Clicked',
  CreateOrderClicked = 'Create Order Clicked',
  CustomRegimenCanceled = 'Custom Regimen Canceled',
  CustomizeRegimenSelected = 'Customize Regimen Selected',
  DeleteClicked = 'Delete Clicked',
  DeleteDraftClicked = 'Delete Draft Clicked',
  DeleteHealthGoalClicked = 'Delete Health Goal Clicked',
  DeliveryBannerClicked = 'Banner Clicked',
  DeliveryPromiseBannerLinkClicked = 'Delivery Promise Banner Clicked',
  DeliveryPromiseBannerDismissedClicked = 'Delivery Promise Banner Dismissed',
  DiscardHealthGoalClicked = 'Discard Health Goal Clicked',
  DispensedAsClicked = 'Dispensed As Clicked',
  DispensedAsSelected = 'Dispensed As Selected',
  DocumentSummaryCollapsed = 'Document Summary Collapsed',
  DocumentSummaryExpanded = 'Document Summary Expanded',
  DrugInteractionDisplayed = 'Drug Interaction Displayed',
  DrugInteractionOverridden = 'Drug Interaction Overridden',
  DueDateClicked = 'Due Date Clicked',
  DurationAdded = 'Duration Added',
  EditFaxClicked = 'Edit Fax Clicked',
  ExistingRegimenSelected = 'Existing Regimen Selected',
  ExternalLinkClicked = 'External Link Clicked',
  ExcludeOONClicked = 'Exclude OON Clicked',
  FamilyHealthHistoryAddClicked = 'Add Family Health History Clicked',
  FamilyHealthHistoryClosed = 'Family Health History Closed',
  FamilyHealthHistoryDeceasedInputClicked = 'Deceased Clicked',
  FamilyHealthHistoryDeleteObjectClicked = 'Delete Family Health History Object Clicked',
  FamilyHealthHistoryDeleteProblemClicked = 'Family Health History Delete Problem Clicked',
  FamilyHealthHistoryNoteIconHovered = 'Note Icon Hovered',
  FamilyHealthHistoryOpened = 'Family Health History Opened',
  FamilyHealthHistorySectionAdded = 'Family Health History Section Added',
  HealthGoalFastOrderClicked = 'Health Goal Fast Order Clicked',
  FieldSelected = 'Field Selected',
  FieldUnselected = 'Field Unselected',
  FreeTextInstructionsAdded = 'Free Text Instructions Added',
  FrequencyChanged = 'Frequency Changed',
  GiveFeedbackClicked = 'Give Feedback Clicked',
  GrowthChartsAvailable = 'Growth Charts Available',
  ImplantableDeviceAddClicked = 'Add Implantable Device Clicked',
  ImplantableDeviceDeleteObjectClicked = 'Delete Implantable Device Object Clicked',
  ImplantableDeviceHideInactiveDevicesClicked = 'Hide Inactive Devices Clicked',
  ImplantableDeviceShowInactiveDevicesClicked = 'Show Inactive Devices Clicked',
  ImplantableDevicesCancelled = 'Implantable Devices Cancelled',
  ImplantableDevicesClosed = 'Implantable Devices Closed',
  ImplantableDevicesOpened = 'Implantable Devices Opened',
  ImplantableDevicesSaved = 'Implantable Devices Saved',
  IndicatedClicked = 'Indicated Clicked',
  InstructionsClicked = 'Instructions Clicked',
  HealthScribePauseRecordingClicked = 'HealthScribe Pause Recording Clicked',
  HealthScribePushToNoteClicked = 'HealthScribe Push to Note Clicked',
  HealthScribeResumeRecordingClicked = 'HealthScribe Resume Recording Clicked',
  HealthScribeSettingsClicked = 'HealthScribe Settings Clicked',
  HealthScribeStartRecordingClicked = 'HealthScribe Start Recording Clicked',
  HealthScribeSummarizeRecordingClicked = 'HealthScribe End and Summarize Recording Clicked',
  HealthScribeSummaryClosedClicked = 'HealthScribe Summary Closed Clicked',
  HealthScribeSummaryRatingSubmitted = 'Summary Rating Submitted',
  HealthScribeTranscriptSectionCollapsed = 'HeathScribe Transcript Section Collapsed',
  HealthScribeTranscriptSectionExpanded = 'HeathScribe Transcript Section Expanded',
  HealthScribeViewTranscriptClicked = 'HealthScribe View Transcript Clicked',
  HighPriorityClicked = 'High Priority Clicked',
  IncludeInMedicalHistoryClicked = 'Include In Medical History Clicked',
  LabsTableFiltered = 'Labs Table Filtered',
  LabsTabOpened = 'Labs Tab Opened',
  LetterCreated = 'Letter Created',
  LetterDraftDeleted = 'Letter Draft Deleted',
  LetterGenerationError = 'Letter Generation Failed',
  LetterGenerationSuccess = 'Letter Generated',
  LetterSigned = 'Letter Signed',
  MarkReviewedClicked = 'Mark Reviewed Clicked',
  MedicationAddedToCart = 'Medication Added to Cart',
  MedicationAverageInsuranceCostClicked = 'Medication Average Insurance Cost Clicked',
  MedicationChangesCancelled = 'Medication Changes Cancelled',
  MedicationChangesSaved = 'Medication Changes Saved',
  MedicationFieldChanged = 'Medication Field Changed',
  MedicationFormularyDetailsViewed = 'Medication Formulary Details Viewed',
  MedicationFormularyAlternativesExpandChanged = 'Medication Formulary Alternatives Expand Changed',
  MedicationFormularyAlternativeSelected = 'Medication Formulary Alternative Selected',
  MessageSent = 'Message Sent',
  MiscNoteCreated = 'Miscellaneous Note Created',
  MLFeedbackGiven = 'ML Feedback Given',
  MLNoteRetitleFeedbackDismissed = 'ML Note Retitle Feedback Dismissed',
  MLNoteRetitleFeedbackSubmitted = 'ML Note Retitle Feedback Submitted',
  MlProcedureBillingSuggestionActivated = 'ML Procedure Billing Suggestion Activated',
  MlProcedureBillingSuggestionChangesDiscarded = 'ML Procedure Billing Suggestion Changes Discarded',
  MlProcedureBillingSuggestionDismissed = 'ML Procedure Billing Suggestion Dismissed',
  MlProcedureBillingSuggestionFeedbackSent = 'ML Procedure Billing Suggestion Feedback Sent',
  MlProcedureBillingSuggestionFeedbackSkipped = 'ML Procedure Billing Suggestion Feedback Skipped',
  MlProcedureBillingSuggestionRecorded = 'ML Procedure Billing Suggestion Recorded',
  MlProcedureBillingSuggestionSaved = 'ML Procedure Billing Suggestion Saved',
  MoreActionsClicked = 'More Actions Clicked',
  NotifyClicked = 'Notify Clicked',
  NewRxsCartUpdated = 'New Rxs Cart Updated',
  OrderModalActivated = 'Order Modal Activated',
  OrderModalActivatedAndCompleted = 'Order Modal Activated and Completed',
  OrderModalNeverMind = 'Order Modal Never Mind Clicked',
  OrderOpened = 'Order Opened',
  PageExited = 'Page Exited',
  PageViewed = 'Page Viewed',
  PatientMenuClicked = 'Patient Level Menu Clicked',
  PatientTimelinePostCreated = 'Patient Timeline Post Created',
  PerformProcedureClicked = 'Perform Procedure Clicked',
  PharmacyListingClicked = 'Pharmacy Listing Clicked',
  PharmacySearchResultsViewed = 'Pharmacy Search Results Viewed',
  PharmacySetAsDestination = 'Pharmacy Set As Destination',
  PharmacySetAsPreferred = 'Pharmacy Set As Preferred',
  PrescriptionSent = 'Prescription Sent',
  PreventionScreeningSectionAdded = 'Prevention & Screening Section Added',
  PrintClicked = 'Print Clicked',
  PrivacyModalClicked = 'Privacy Modal Clicked',
  PrivacyModalDisplayed = 'Privacy Modal Displayed',
  PRNClicked = 'PRN Clicked',
  ProblemAttachmentLinked = 'Problem Attachment Linked',
  ProblemAttachmentUnlinked = 'Problem Attachment Unlinked',
  ProblemAttachmentSearchedInSelector = 'Problem Attachment Searched In Selector',
  ProblemAttachmentSelectorOpened = 'Problem Attachment Selector Opened',
  ProblemAttachmentSelectorClosed = 'Problem Attachment Selector Closed',
  ProblemDecisionSupportExpanded = 'Problem Decision Support Expanded',
  ProblemStatusClicked = 'Problem Status Clicked',
  ProcedureOrderFieldChanged = 'Order Field Changed',
  PRNReasonSelected = 'PRN Reason Selected',
  ProgramSuggestionsDisplayed = 'Program Suggestions Displayed',
  ProgramSuggestionsClicked = 'Program Suggestions Clicked',
  ProgramSuggestionsSummaryShown = 'Program Suggestions Summary Shown',
  RegimenEditorCanceled = 'Regimen Editor Canceled',
  RegimenEditorOpened = 'Regimen Editor Opened',
  RegimenStateAfterUpdate = 'Regimen State After Update',
  RegimenUpdated = 'Regimen Updated',
  RequestedMeasuresClicked = 'Requested Measures Clicked',
  RequestProblemTipClicked = 'Request A Tip for This Problem Clicked',
  SampleCollectedClicked = 'Sample Collected Clicked',
  SaveFaxOverride = 'Save Fax Clicked',
  ScreeningHistoryCancelled = 'Screening History Cancelled',
  ScreeningHistoryDeleted = 'Screening History Deleted',
  ScreeningHistoryOpened = 'Screening History Opened',
  ScreeningHistorySaved = 'Screening History Saved',
  SeriesVaccineToggled = 'Series Vaccine Toggled',
  SearchClicked = 'Search Clicked',
  ShowFullRxHistoryClicked = 'Prescription History Clicked',
  SignOrderClicked = 'Sign Order Clicked',
  SmokingStatusAddClicked = 'Add Smoking Status Clicked',
  SmokingStatusClosed = 'Smoking Status Closed',
  SmokingStatusEditOpened = 'Edit Smoking Status Opened',
  SmokingStatusReviewed = 'Smoking Status Reviewed',
  SmokingStatusSuggestionActivated = 'Smoking Status Suggestion Activated',
  SmokingStatusSuggestionConfirmed = 'Smoking Status Suggestion Confirmed',
  SmokingStatusSuggestionDismissed = 'Smoking Status Suggestion Dismissed',
  SortConsultOrderClicked = 'Sort Consult Order Clicked',
  SpecifyDurationSelected = 'Specify Duration Selected',
  SummaryAdded = 'Summary Added',
  SummarySigned = 'Summary Signed',
  SummaryViewed = 'Summary Viewed',
  TemplateInlineInserted = 'Template Inline Inserted',
  TemplateInlineMenuActivated = 'Template Inline Menu Activated',
  TemplateInserted = 'Template Inserted',
  TemplateInsertStarted = 'Template Insert Started',
  TimelineFilterSelected = 'Timeline Filter Selected',
  TimelineOpened = 'Timeline Opened',
  TimelineSearch = 'Clinical Timeline Search Executed',
  ToolTipClicked = 'Tool Tip Clicked',
  VisitCodeSelected = 'Visit Code Selected',
  UseStructuredDataSelected = 'Use Structured Data Selected',
  UseFreeTextSelected = 'Use Free Text Selected',
  QuantityChanged = 'Quantity Changed',
  UnsignedSummaryViewed = 'Unsigned Summary Viewed',
  VaccinationsTabClicked = 'Vaccinations Tab Clicked',
  VariableInserted = 'Variable Inserted',
  ViewHideTipsClicked = 'View/Hide Tips Clicked',
  ViewPrimePricingLinkClicked = 'View Prime Pricing Link Clicked',
  WriteRxOptionClicked = 'Write Rx Clicked',
  WriteRxOptionDiscontinueClicked = 'Discontinue Clicked',
}

export interface TrackEventProperties {
  activeCharting: boolean;
  admissionId?: number;
  allergiesId: number;
  amazonPharmacyDetailTableExpanded: boolean;
  amazonPharmacyListed: boolean;
  application: string;
  appointmentId: number;
  appointmentType: string;
  apptTypeId: number;
  availablePrescriptionCount: number;
  bannerText: string;
  cartId: number;
  checkBoxShown: boolean;
  clickType: string;
  component: string;
  contact: string;
  cosignatureRequested: boolean;
  currentlyUnavailablePillShown: boolean;
  currentlyUnavailablePillCount: number;
  description: string;
  deliveryBannerShown: boolean;
  deliveryBannerText: string;
  deliveryPromiseBannerShown: boolean;
  deliveryPromiseBannerText: string;
  deliveryPromiseVariation: string;
  dispensableId: number;
  dispensableTextDesc: string;
  documentId: number;
  doseHigh: string;
  doseLow: string;
  dueDate: string;
  duration: number;
  durationDays: number;
  excludeOon: boolean;
  experiments: LaunchDarklyExperiment[];
  familyHealthHistoryId: number;
  feedbackResponse: string;
  filterId: number;
  filterType: string;
  filterValue: string;
  formType: string;
  frequencyIntervalId: number;
  frequencyIntervalTextShort: string;
  healthGoalId: number;
  healthGoalTypeId: number;
  isDue: boolean;
  actionType: string;
  isFieldUpdated: boolean;
  highPriority: boolean;
  implantableDeviceId: number;
  includeInMedicalHistory: boolean;
  inputString: string;
  instructions: string;
  instructionsText: string;
  interactingObjectId: number;
  interactingObjectType: string;
  isCustom: boolean;
  isDeceased: boolean;
  isHighPriority: boolean;
  isNew: boolean;
  isOverridden: boolean;
  isSuccessful: boolean;
  isUrgent: string;
  labCompany: string;
  labTestCode: string;
  letterId: number;
  measure: string;
  medicationRegimenId: number;
  medicationRegimenTextDesc: string;
  medicationRouteId: number;
  medicationRxPassEligible: boolean;
  medicationsId: number;
  medicationsName: string;
  messageDraftType: string;
  method: string;
  noteCreatedById: string;
  noteId?: number;
  noteTypeId: number;
  noteType: string;
  objectId: number | string;
  orderDetails: string;
  orderId?: number | null;
  orderStatus: string;
  orderSubtype: string;
  orderType: string;
  pageNumber: number;
  patientId: number;
  patientTimelinePostId?: number | null;
  pharmacyAdded: string;
  pharmacyAddedId: number;
  pharmacyInfoTextField1: string;
  pharmacyInfoTextField2: string;
  pharmacyItemNumber: number;
  pharmacyClicked: string;
  pharmacyClickedId: number;
  pharmacyDestination: string;
  pharmacyDestiantionId: number;
  pharmacyDestinationType: string;
  pixelDistance: number;
  position?: number;
  prescriptionCount: number;
  preferredPharmacyId: number;
  preferredPharmacyName: string;
  preferredPharmacyType: string;
  prnDesc: string;
  prnId: number;
  problemCode: string;
  problemDesc: string;
  problemId: number | string;
  problemStatus: string;
  problemType: string;
  problemTypeId: number;
  procedureCodeCpt: string;
  procedureCodeCptDescription: string;
  procedureCodeId: number;
  programCount: number;
  programName: string | string[];
  quantity: number;
  resultsReturned: number;
  rxPassEligibleCount: number;
  rxPassEligibleShown: boolean;
  rxSourceId: number;
  rxSourceType: string;
  screeningId: number;
  searchDuration: number;
  searchInputLocationValue: string;
  searchInputNameValue: string;
  searchShowOtherAddress: boolean;
  searchOpen24h: boolean;
  searchType: string;
  sessionFingerprint: number;
  severityLevel: number;
  signOnBehalfOf: boolean;
  source: string;
  smokingStatusId: number;
  subcomponent: string;
  subworkflowId: number;
  suggestionFeedback: string;
  suggestionId: number;
  summaryId?: number | null | string;
  supporterType: string;
  templateId: number;
  templateName: string;
  templateType: string;
  timeFrameCurrent: string;
  timeFrameDefault: string;
  timeFrameSelected: string;
  todoId?: number;
  totalItems?: number;
  type: string;
  unavailablePrescriptionCount: number;
  unit: string;
  usePrn: boolean;
  userRole: string;
  useStructuredData: boolean;
  vaccineId: number;
  vaccineOrderId: number;
  vaccineName: string;
  variableName: string;
  workflow: string;
  [key: string]: any; // Index signature for additional properties
}

export type AnalyticsApiRequestData = any;
