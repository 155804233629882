import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { ProfileSelectors, SearchService } from '@app/core';
import { ConfigService } from '@app/core/config';
import { QueryBuilder } from '@app/core/search/query-builder';
import { filterTruthy } from '@app/utils';

import { Template } from './template-insertion.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { TemplateSearchService } from './template-search.service';
import { RealUserMonitoring } from '@app/core/monitoring';

export type ChartingTemplatesSource =
  | 'opensearch-proxy'
  | 'onelife-monolith-graphql';

interface SearchResult<T> {
  sort: any;
  _id: string;
  _index: string;
  _score: number;
  _source: T;
  _type: string;
}

interface TemplateSearchDocument {
  body: string;
  created_at: string;
  id: number;
  internal_user_id: number | null;
  name: string;
  text_template_type_id: number | null;
  text_template_type_name: string | null;
  updated_at: string | null;
  purpose: string | null;
}

interface TemplateSearchQueryOptions {
  publicOnly?: boolean;
}

@Injectable()
export class TemplateInsertionService {
  private searchService = inject(SearchService);
  private templateSearchService = inject(TemplateSearchService);
  private config = inject(ConfigService);
  private profileSelectors = inject(ProfileSelectors);
  private launchDarklyService = inject(LaunchDarklyService);

  private rumService = inject(RealUserMonitoring);

  searchForTemplate(
    text: string,
    index = this.config.searchIndex('message_templates'),
    options?: TemplateSearchQueryOptions,
  ): Observable<Template[]> {
    const publicOnly = (options && options.publicOnly) || false;

    const source = this.launchDarklyService.variation<ChartingTemplatesSource>(
      FeatureFlagNames.chartingTemplatesSource,
      'opensearch-proxy',
    );

    const rumEventName =
      index === this.config.searchIndex('message_templates')
        ? 'com.onemedical.ui.message_template_queried'
        : 'com.onemedical.ui.text_template_queried';

    if (source === 'onelife-monolith-graphql') {
      return this.templateSearchService.search(text, index, options).pipe(
        tap(() => {
          this.rumService.recordEvent(rumEventName, {
            source,
          });
        }),
      );
    }

    return this.profileSelectors.profileId.pipe(
      filterTruthy(),
      switchMap(profileId =>
        this.searchService
          .search(this.buildSearchQuery(index, text, profileId, publicOnly))
          .pipe(
            map(response => {
              const hits = response.hits || {};
              const items = hits.hits || [];

              return items.sort(this.sortSearchResults).map(hit => hit._source);
            }),
            tap(() =>
              this.rumService.recordEvent(rumEventName, {
                source,
              }),
            ),
          ),
      ),
    );
  }

  private sortSearchResults(
    a: SearchResult<TemplateSearchDocument>,
    b: SearchResult<TemplateSearchDocument>,
  ): number {
    const aIsPersonal = a._source.purpose === 'personal';
    const bIsPersonal = b._source.purpose === 'personal';
    if ((aIsPersonal && bIsPersonal) || (!aIsPersonal && !bIsPersonal)) {
      // If templates are both personal or both public, sort by score
      return b._score - a._score;
    } else {
      // If one template is personal, sort that one first
      return aIsPersonal ? -1 : 1;
    }
  }

  private buildSearchQuery(
    index: string,
    text: string,
    profileId: number,
    publicOnly: boolean,
  ): QueryBuilder {
    const queryOptions = {
      size: '200',
      fields: ['name'],
      sort: ['_score', 'name.keyword'],
      operator: 'and',
      index: [index],
      filter: {
        bool: {
          should: this.setQueryOptionsFilters(profileId, publicOnly),
        },
      },
    };

    return new QueryBuilder('multi_match_with_fields_v6_strategy').build(
      text,
      queryOptions,
    );
  }

  private setQueryOptionsFilters(profileId: number, publicOnly: boolean) {
    const queryFilters: Array<any> = [{ term: { purpose: 'public' } }];

    if (!publicOnly) {
      queryFilters.unshift({ term: { internal_user_id: profileId } });
    }

    return queryFilters;
  }
}
