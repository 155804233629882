<ng-container *ngIf="profile$ | ngrxPush as profile">
  <div class="banner -info">
    <b>Prescriber</b>
    <ul>
      <li>Patient Primary Address: {{ patientPrimaryAddress$ }}</li>
      <li>numberOfValidPrescribers : {{ numberOfValidPrescribers }}</li>
      <li>hasSignatureImage: {{ profile.hasSignatureImage }}</li>
      <li>pharmacyAddress state: {{ pharmacyAddress?.state }}</li>
      <li>Mail Order: {{ pharmacyInfo }}</li>
    </ul>
  </div>

  <div
    class="banner -warning"
    *ngIf="(numberOfValidPrescribers === 0 || !profile.hasSignatureImage) && pharmacyAddress?.state"
  >
    <ul>
      <li *ngIf="(isProvider$ | ngrxPush) && !profile.hasSignatureImage">
        You are missing a Signature Image. This will prevent you from signing medications on behalf of yourself.
      </li>
      <li *ngIf="numberOfValidPrescribers === 0 && profile.epcsEulaMissing">
        You do not have the proper prescribing credentials. Prescribers must sign the
        <a omgLegacyUiHref="/admin/controlled_substance_eula" title="EULA">EULA</a>
        to prescribe controlled substances.
      </li>
      <li *ngIf="(numberOfValidPrescribers === 0 && !(patientIsVirtual$ | ngrxPush)) || !(isProvider$ | ngrxPush)">
        You do not have {{ hasControlledSubstances ? 'controlled substance' : '' }}
        prescribing credentials for
        {{ pharmacyAddress?.state }}. If you believe this is an error, please contact creds&#64;onemedical.com.
      </li>
      <li *ngIf="(numberOfValidPrescribers === 0 && (patientIsVirtual$ | ngrxPush)) || !(isProvider$ | ngrxPush)">
        You do not have the correct prescribing credentials for this OM Now patient. If you believe this is an error,
        please contact creds&#64;onemedical.com.
      </li>
    </ul>
  </div>
</ng-container>
