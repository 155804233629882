@if (note) {
  <div
    [omgRecordEventOn]="{
      listenFor: 'init',
      eventName: 'com.onemedical.ui.note_loaded',
      eventData: { component: 'Note' },
    }"
  >
    <form [omgForm]="noteFormModel" [formGroup]="noteFormModel.form">
      <omg-card-title>
        <div omgCollapse #noteCollapse="omgCollapseProvider" [expanded]="note.totalComments > 0">
          <omg-note-header
            [note]="note$ | ngrxPush"
            [todo]="todo$ | ngrxPush"
            [editable]="noteHasEditableTitle$ | ngrxPush"
            (closeNoteHeader)="onCloseNote()"
            [form]="noteFormModel.form"
            [commentsCollapseRef]="noteCollapse"
          ></omg-note-header>
        </div>
      </omg-card-title>
      <omg-card-body data-cy="note-card">
        <div omgScrollingContainer #scrollableContainer>
          <!-- Comments -->
          <omg-expanded [collapseProvider]="noteCollapse">
            <omg-comments
              [commentable]="commentable"
              (commentAdded)="onCommentUpdate('add')"
              (commentRemoved)="onCommentUpdate('remove')"
            ></omg-comments>
          </omg-expanded>
          <div om-layout="vertical" om-layout-gutter>
            @if (noteHasIncompleteTodo$ | ngrxPush) {
              <omg-task-assignment
                (focusOn)="trackAssignmentSelected()"
                (focusOut)="trackAssignmentUnselected()"
                (clicked)="highPriorityClicked()"
                [todo]="todo$"
              ></omg-task-assignment>
            }
            @if (noteHasIncompleteTodo$ | ngrxPush) {
              <omg-task-template-selector
                [inProgress]="todoIsUpdating$ | ngrxPush"
                [todo$]="todo$"
              ></omg-task-template-selector>
            }
          </div>
          @if (note.mlNoteSuggestion && !note.mlNoteSuggestion.mlFeedback) {
            <omg-ml-note-retitle-feedback [note$]="note$"></omg-ml-note-retitle-feedback>
          }
          <!-- Note body -->
          @if (showRichTextNoteBodyEditor) {
            <div class="padding-normal">
              <omg-chart-text-box
                templateType="text"
                [scrollingContainer]="scrollableContainer"
                [control]="noteFormModel.form.get('body')"
                quillFormat="html"
                placeholderText="Type message here..."
                [insertionEventProps]="{
                  component: note.noteType.name,
                  subcomponent: 'Note Body',
                }"
                (focusTextBox)="trackMessageSelected()"
                (blurTextBox)="trackMessageUnselected()"
              ></omg-chart-text-box>
            </div>
          }
          @if (showSimpleNoteBodyEditor) {
            <div class="padding-normal">
              <label>
                Note Body
                <omg-chart-text-box
                  [control]="noteFormModel.form.get('body')"
                  [insertionEventProps]="{
                    component: note.noteType.name,
                    subcomponent: 'Note Body',
                  }"
                ></omg-chart-text-box>
              </label>
            </div>
          }
          @if (showReadOnlyNoteBody && !showSimpleNoteBodyEditor) {
            <div
              class="padding-normal note-body"
              omgTheme
              [ngClass]="getClassForNoteBody()"
              [innerHTML]="note.body"
            ></div>
          }
          @for (attachment of noteFormModel.get('attachments').value; track attachment) {
            <div class="padding-normal">
              <omg-file-link
                [url]="attachment.viewingPath"
                [text]="attachment.name"
                [deletable]="isAttachable"
                (delete)="onDeleteAttachment(attachment)"
              ></omg-file-link>
            </div>
          }
          <!-- Documents -->
          @for (doc of noteFormModel.get('documents').value; track doc; let index = $index) {
            <div class="padding-normal" om-layout="vertical" om-layout-gutter>
              <div om-layout-gutter>
                <omg-document-summary [documentId]="doc.id" [noteTypeName]="note.noteType?.name"></omg-document-summary>
                <omg-problem-selector
                  (trackSelectionRemoved)="selectionRemoved()"
                  (trackSelectionAdded)="selectionAdded()"
                  (trackTypeaheadOpened)="typeaheadOpened()"
                  (trackTypeaheadClosed)="typeaheadClosed()"
                  [documentId]="doc.id"
                ></omg-problem-selector>
                @if (!doc.forLab) {
                  <omg-file-link
                    [key]="doc.awsKey"
                    [encode]="false"
                    [bucket]="doc.awsBucket"
                    text="Download File"
                  ></omg-file-link>
                }
                @if (showRecordProcedureButton$(doc.id) | ngrxPush) {
                  <button (click)="toggleProcedureCaptureForm(doc.id)" omgButton variant="secondary">
                    {{ getProcedureCaptureButtonText$(doc.id) | ngrxPush }}
                  </button>
                }
              </div>
              @if (canRecordHistoricalProcedure) {
                <omg-historical-procedure-capture
                  [documentId]="doc.id"
                  [attemptedNoteSubmit]="isConfirmingProcedure"
                ></omg-historical-procedure-capture>
              }
              @if (doc.forLab) {
                <omg-lab-result
                  [awsKey]="doc.awsKey"
                  [awsBucket]="doc.awsBucket"
                  [labDataId]="doc.parentId"
                  [patientId]="patient?.id"
                ></omg-lab-result>
              }
              @if (onIsPdfDocument(doc)) {
                <omg-pdf-viewer class="workspace-column" [key]="doc.pdfKey" [bucket]="doc.pdfBucket"></omg-pdf-viewer>
              }
            </div>
          }
          @if (note.faxes.length > 0) {
            <omg-transmission-details [history]="noteFormInfo.transmissionDetails"></omg-transmission-details>
          }
          <omg-form-state when="saveError">
            <ng-template let-change>
              @for (msg of change.error; track msg) {
                <div class="om-messages">
                  {{ msg }}
                </div>
              }
            </ng-template>
          </omg-form-state>
          @if (attachError) {
            <div class="padding-normal om-messages -warning">{{ attachError }}</div>
          }
          <div class="padding-normal">
            @if (nextStepsEnabled$ | ngrxPush) {
              <div class="banner -instructional" data-cy="next-steps-instructions-banner">
                This task type has follow-up steps. Fill in the details you can above, then click "Next Step" to
                reassign or repeat.
              </div>
            }
          </div>
        </div>
      </omg-card-body>
      <omg-card-footer>
        <omg-collapse #noteActionBarCollapse="omgCollapse">
          @if (isConfirmingNextStep) {
            <omg-expanded>
              <form [formGroup]="form">
                <div
                  class="action-bar"
                  om-layout
                  data-cy="expanded-next-step-confirmation"
                  om-layout-align="space-between center"
                >
                  <omg-dropdown
                    class="om-dropdown"
                    data-cy="next-step-dropdown"
                    [options]="nextSteps$ | ngrxPush"
                    [flex]="true"
                    [placeholder]="'Select next step'"
                    formControlName="selectedNextStep"
                  ></omg-dropdown>
                  <span om-layout om-layout-gutter om-layout-align="space-between center">
                    <button omgButton variant="flat" [disabled]="todoIsUpdating" (click)="toggleNextStepSelection()">
                      Cancel
                    </button>
                    <button
                      type="button"
                      omgButton
                      variant="primary"
                      data-cy="expanded-next-step-btn"
                      [disabled]="todoIsUpdating"
                      (click)="onNextStepSelected()"
                    >
                      Finish & Create
                    </button>
                  </span>
                </div>
              </form>
            </omg-expanded>
          }
          @if (isDeleting) {
            <omg-expanded>
              <div class="action-bar -warning" om-layout om-layout-align="space-between center">
                <b>Permanently delete this note?</b>
                <span om-layout om-layout-gutter om-layout-align="space-between center">
                  <button omgButton variant="flat" (click)="onToggleDeleting()">Never mind</button>
                  <button type="button" omgButton variant="primary" (click)="onDelete()">Delete Note</button>
                </span>
              </div>
            </omg-expanded>
          }
          @if (isConfirmingProcedure) {
            <omg-expanded>
              <div class="action-bar" om-layout om-layout-align="space-between center">
                <b>Review without saving procedure?</b>
                <span om-layout om-layout-gutter om-layout-align="space-between center">
                  <button omgButton variant="flat" (click)="onToggleProcedureConfirm()">Cancel</button>
                  <button type="button" omgButton variant="primary" (click)="doFinishNote(todo)">Review</button>
                </span>
              </div>
            </omg-expanded>
          }
          @if (isConfirmingSignature) {
            <omg-expanded>
              <div
                class="action-bar -warning"
                om-layout
                om-layout-align="space-between center"
                data-cy="note-confirm-signature"
              >
                <b>Are you sure?</b>
                <span om-layout om-layout-gutter om-layout-align="space-between center">
                  <button omgButton variant="flat" (click)="onToggleConfirm()">Never mind</button>
                  <button type="button" omgButton variant="primary" (click)="doFinishNote(todo)">Sign Note</button>
                </span>
              </div>
            </omg-expanded>
          }
          @if (isRedacting) {
            <omg-expanded>
              <div class="action-bar -warning" om-layout om-layout-align="space-between center">
                <b>Permanently redact this note?</b>
                <span om-layout om-layout-gutter om-layout-align="space-between center">
                  <button omgButton variant="flat" (click)="onToggleRedact()">Never mind</button>
                  <button type="button" omgButton variant="primary" (click)="onRedactNote()">Redact</button>
                </span>
              </div>
            </omg-expanded>
          }
          @if (showTemplateInsertionBar()) {
            <omg-template-insertion-action-bar
              [collapseRef]="noteActionBarCollapse"
              [index]="noteTemplatesIndex"
              (apply)="onApplyNoteTemplate($event)"
            ></omg-template-insertion-action-bar>
          }
          @if (isRecategorizing) {
            <omg-expanded>
              <omg-recategorize-document-action-bar
                [index]="recategorizeSearchIndex"
                (cancel)="onCancelRecategorize()"
                (apply)="onRecategorizeDocument($event)"
              ></omg-recategorize-document-action-bar>
            </omg-expanded>
          }
          <!-- Default action bar -->
          <omg-collapsed>
            <div class="action-bar" [hidden]="hasTodoError | ngrxPush" om-layout>
              <div om-layout om-layout-align="start center" om-layout-gutter om-layout-fill>
                <omg-form-state>
                  <ng-template let-change>
                    <div om-layout om-layout-gutter>
                      @if ((noteHasIncompleteTodo$ | ngrxPush) && !(nextStepsEnabled$ | ngrxPush)) {
                        <button
                          omgButton
                          variant="primary"
                          [ngClass]="{ '-processing': change.state === 'saving' }"
                          type="submit"
                          [disabled]="
                            change.state === 'saving' || (formUploading$ | ngrxPush) || (todoIsUpdating$ | ngrxPush)
                          "
                          data-cy="finish-note-btn"
                          (click)="onFinishNote(todo)"
                        >
                          {{
                            change.state === 'saving' || (todoIsUpdating$ | ngrxPush)
                              ? 'Saving...'
                              : (finishLabel$ | ngrxPush)
                          }}
                        </button>
                      }
                      @if (nextStepsEnabled$ | ngrxPush) {
                        <omg-split-button
                          variant="primary"
                          data-cy="finish-menu"
                          [defaultButtonDataCy]="'next-step-button'"
                          [dropdownCaratDataCy]="'next-step-dropdown-carat'"
                          [items]="nextStepOptions.items"
                          [label]="(inProgress$ | ngrxPush) ? nextStepOptions.inProgressLabel : nextStepOptions.label"
                          [disabled]="inProgress$ | ngrxPush"
                          (labelClick)="nextStepOptions.command()"
                        ></omg-split-button>
                      }
                      @if (note.noteType.name === 'Misc Clinical') {
                        <button
                          omgButton
                          variant="link"
                          (click)="openVMTHelpModal()"
                          [disabled]="change.state === 'saving'"
                          [omgTrack]="{
                            event: 'click',
                            action: 'Create HelpOut Request',
                          }"
                          data-cy="create-vmt-help-request-link"
                        >
                          Create HelpOut Request
                        </button>
                      }
                    </div>
                    @if (!(noteHasIncompleteTodo$ | ngrxPush)) {
                      <ng-container *ngTemplateOutlet="secondaryActionsButton"></ng-container>
                    }
                  </ng-template>
                </omg-form-state>
              </div>
              <div om-layout om-layout-align="end center" om-layout-gutter-large om-layout-fill>
                @if (noteHasIncompleteTodo$ | ngrxPush) {
                  <ng-container *ngTemplateOutlet="secondaryActionsButton"></ng-container>
                }
                @if (isAttachable) {
                  <omg-file-upload-button
                    om-layout
                    om-layout-align="space-between center"
                    (fileChosen)="onAddAttachments($event)"
                  ></omg-file-upload-button>
                }
              </div>
              <ng-template #secondaryActionsButton>
                @if (secondaryActionButtonParams$ | ngrxPush; as secondaryActionButton) {
                  <omg-split-button
                    [items]="secondaryActionButton.items"
                    [label]="
                      (inProgress$ | ngrxPush) ? secondaryActionButton.inProgressLabel : secondaryActionButton.label
                    "
                    [disabled]="inProgress$ | ngrxPush"
                    (labelClick)="secondaryActionButton.command()"
                  ></omg-split-button>
                }
              </ng-template>
              @if (noteHasIncompleteTodo$ | ngrxPush) {
                <div class="action-bar -error" [hidden]="!(hasTodoError | ngrxPush)">
                  {{ todoError | ngrxPush }}
                </div>
              }
            </div>
          </omg-collapsed>
        </omg-collapse>
      </omg-card-footer>
    </form>
  </div>
}

@if (patient && note) {
  <omg-printable-note [hasDocuments]="hasDocuments" [patient]="patient" [note]="note"></omg-printable-note>
}
