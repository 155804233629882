<omg-card class="workspace-card" [loading]="!(summary | ngrxPush)" omgTheme>
  <ng-template #loadedRef>
    @if (summary | ngrxPush; as summary) {
      <div
        data-cy="note-content"
        [omgRecordEventOn]="{
          listenFor: 'init',
          eventName: 'com.onemedical.ui.summary_loaded',
          eventData: { component: 'Summaries' },
        }"
      >
        <omg-card-title>
          <div
            class="om-tab-subheading"
            omgCollapse
            [expanded]="summary.note.totalComments > 0"
            #commentsRef="omgCollapse"
            om-layout
            om-layout-fill
            om-layout-gutter
            om-layout-align="space-between center"
          >
            <omg-note-icon [tags]="summary?.noteType?.tags" [noteType]="summary?.noteType?.name"></omg-note-icon>

            @if (!summary.signed) {
              <span om-flex>
                @if (isAppointmentSummary | ngrxPush) {
                  <span om-flex>
                    <strong>{{ summary.appointment?.type }}</strong>
                    ({{ summary.appointment?.startAt | omgDate: '12Time' }}—{{
                      summary.appointment?.endAt | omgDate: '12Time'
                    }})
                    {{ summary.appointment?.startAt | omgDate: '2y' }}
                  </span>
                }

                @if (isNoteOrWalkinVisit | ngrxPush) {
                  <span om-flex data-cy="summary-note-title" class="summary-note-title">
                    {{ summary.noteType.name }} Note
                  </span>
                }
              </span>
            }
            @if (summary.signed) {
              <span om-flex om-layout="vertical">
                <span class="summary-note-title">{{ summary.subject | slice: 0 : 80 }}</span>
                @if (summary.signed) {
                  <omg-note-history [note]="summary"></omg-note-history>
                }
              </span>
            }

            <!-- Comment icon / toggle -->
            <omg-collapse-toggle
              om-layout
              om-layout-align="space-between center"
              om-layout-gutter-mini
              class="collapse-with-flex"
              (toggle)="trackCommentToggle($event)"
            >
              <omg-collapsed disableAnimation="true">
                <span class="om-icon icon-comment clickable"></span>
              </omg-collapsed>

              <omg-expanded disableAnimation="true">
                <span class="om-icon icon-comment-filled clickable"></span>
              </omg-expanded>
              <span>{{ summary?.note?.totalComments }}</span>
            </omg-collapse-toggle>

            <!-- Close summary button -->
            @if (!(workspaceExpanded$ | ngrxPush)) {
              <span
                class="om-icon icon-close clickable"
                omStopEvent="click"
                data-cy="close-note-btn"
                (click)="onCloseSummary(summary)"
              ></span>
            }

            <!-- Collapse service ticket button -->
            @if (workspaceExpanded$ | ngrxPush) {
              <span
                class="fa fa-expand clickable"
                omStopEvent="click"
                data-cy="collapse-note-btn"
                (click)="closeServiceTicket()"
              ></span>
            }
          </div>
        </omg-card-title>

        @if (!summary.signed) {
          <omg-unsigned-summary
            [summary]="summary"
            [todo$]="todo"
            [commentsCollapseRef]="commentsRef"
            [appointmentRecording]="appointmentRecording"
            [healthScribeSettings]="healthScribeSettings"
            [appointmentRecordingIsLoading]="appointmentRecordingIsLoading"
          ></omg-unsigned-summary>
        } @else {
          <omg-signed-summary
            [summary]="summary"
            [appointmentRecording]="appointmentRecording"
            [appointmentRecordingIsLoading]="appointmentRecordingIsLoading"
            [todo]="todo | ngrxPush"
            [commentsCollapseRef]="commentsRef"
            [patient]="patient | ngrxPush"
            [profilePrimaryName]="profilePrimaryName | ngrxPush"
            [hasIncompleteCosignTodo]="hasIncompleteCosignTask | ngrxPush"
            [note]="note | ngrxPush"
          ></omg-signed-summary>
        }
      </div>
    }
  </ng-template>
  <ng-template #loadingRef>
    <omg-card-title heading="Summary"></omg-card-title>
    <omg-feature-loading-spinner></omg-feature-loading-spinner>
  </ng-template>
</omg-card>
