import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';
import { PatientSelectors, Profile, ProfileSelectors } from '@app/core';
import {
  PharmacyAddress,
  RenewalPharmacy,
} from '@app/modules/pharmacy-picker/shared/pharmacy.type';

@Component({
  selector: 'omg-rx-credential-warning',
  templateUrl: './rx-credential-warning.component.html',
})
export class RxCredentialWarningComponent implements OnInit {
  @Input() numberOfValidPrescribers: number;
  @Input() hasControlledSubstances: boolean;
  @Input() pharmacyAddress: PharmacyAddress;
  @Input() pharmacyIsMailOrder: boolean;
  @Input() pharmacyInfo: RenewalPharmacy;

  profile$: Observable<Profile>;
  isProvider$: Observable<boolean>;
  patientIsVirtual$: Observable<boolean>;
  patientPrimaryAddress$: Subscription;

  constructor(
    private profileSelectors: ProfileSelectors,
    private patientSelectors: PatientSelectors,
  ) {}

  ngOnInit() {
    this.setupSelectors();
  }

  // patientPrimaryAddress() {
  //   this.patient$.pipe(filterTruthy()).subscribe((patient: Patient) => {
  //     patient.primaryAddress;
  //   });
  //
  // }

  private setupSelectors(): void {
    this.profile$ = this.profileSelectors.profile;
    this.isProvider$ = this.profileSelectors
      .hasRole('provider')
      .pipe(take(1), shareReplay(1));
    this.patientIsVirtual$ = this.patientSelectors.isVirtual;
    // this.patient$ = this.patientSelectors.patient;
    this.patientPrimaryAddress$ = this.patientSelectors.addresses[0];
    // this.patientPrimaryAddress$ = this.patientSelectors.patient
    //   .pipe(filterTruthy()).subscribe((patient: Patient) => {
    //     JSON.stringify(patient.primaryAddress.state)
    // });
  }
}
